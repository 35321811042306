//import axios from 'axios';
import Http from "./http.init";
import { ResponseWrapper, ErrorWrapper } from "./util";
const { API } = require("axios-client"); //require('../../api/axios-client');
const axios = API();
var qs = require("qs");
export default class BaseService {
  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */
  constructor(payload = { baseURL: {} }) {
    this.configuration.baseURL = payload.baseURL;
  }

  configuration = {
    baseURL: "",
    responseType: "",
    _headers: {},
    set headers(header) {
      this._headers = { ...this._headers, ...header };
    },
    get headers() {
      return this._headers;
    },
    callbacks: {
      request: {
        onRequest_Event: undefined,
        requestParams: {},
        onError_Event: undefined,
        errorParams: {},
      },
      response: {
        onResponse_Event: undefined,
        responseParams: {},
        onError_Event: undefined,
        errorParams: {},
      },
      params: {},
    },
  };

  request(status) {
    // status.reqHeader = { ...this.reqHeader, ...status.headers };
    // status.baseURL = this.baseURL;
    return new Http(this.configuration);
  }

  responseWrapper(...rest) {
    return new ResponseWrapper(...rest);
  }

  errorWrapper(...rest) {
    return new ErrorWrapper(...rest);
  }

  /**
   * ------------------------------
   * @API_CALLS
   * ------------------------------
   */

  login(url, auth = true, data = window.required(), config = {}, parameters = {}) {
    const params = {
      ...parameters,
    };

    return new Promise((resolve, reject) => {
      return this.request({ auth, config, headers: { "api-security-key": process.env.VUE_APP_API_SECURITY_KEY, contentType: "application/x-www-form-urlencoded" } })
        .post(`${url}`, data, { params })
        .then((response) => {
          return resolve(new ResponseWrapper(response, response.data));
        })
        .catch((error) => {
          let message = error.response.data ? error.response.data.error_description : error.response.status;
          reject(new ErrorWrapper(error, message));
        });
    });
  }

  get(url, auth = true, parameters = {}) {
    const params = {
      ...parameters,
    };

    const cache = axios.cachedItems;

    let key;

    if (params) {
      var query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      key = url + "?" + query;
    } else {
      key = url;
    }

    // if (cache && cache.has(key)) {
    //   return Promise.resolve(cache.get(key));
    // } else {
      return new Promise((resolve, reject) => {
        return this.request({ auth })
          .get(url, {
            params: parameters,
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          })
          .then((response) => {
            cache && cache.set(key, response);
            resolve(this.responseWrapper(response, response.data));
          })
          .catch((error) => {
            let message = error.response.data ? error.response.data.Message : error.response.status;
            reject(this.errorWrapper(error, message));
          });
      });
    // }
  }

  post(url, auth = true, data = window.required(), parameters = {}) {
    const params = {
      ...parameters,
    };
    return new Promise((resolve, reject) => {
      return this.request({ auth })
        .post(`${url}`, data, { params })
        .then((response) => resolve(this.responseWrapper(response, response.data)))
        .catch((error) => {
          let message = error.response.data ? error.response.data.Message : error.response.status;
          reject(this.errorWrapper(error, message));
        });
    });
  }

  put(url, auth = true, id = window.required(), data = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth })
        .put(`${url}/${id}`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data)))
        .catch((error) => {
          let message = error.response.data ? error.response.data.Message : error.response.status;
          reject(this.errorWrapper(error, message));
        });
    });
  }

  patch(url, auth = true, id = window.required(), data = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth })
        .patch(`${url}/${id}`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data)))
        .catch((error) => {
          //if (error.response.status !== 401)
          {
            let message = error.response.data ? error.response.data.Message : error.response.status;
            reject(this.errorWrapper(error, message));
          }
        });
    });
  }

  delete(url, auth = true, id = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth })
        .delete(`${url}/${id}`)
        .then((response) => resolve(this.responseWrapper(response, response.data)))
        .catch((error) => {
          let message = error.response.data ? error.response.data.Message : error.response.status;
          reject(this.errorWrapper(error, message));
        });
    });
  }
}
