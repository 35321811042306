import { servicesFactory } from "../../../services/serviceFactory.js";
import { appConstants } from "../../../common/constants.js";
export default {
  namespaced: true,
  state: () => ({
    courses: [],
    totalRecords: 0
  }),
  getters: {
    specialCourses(state) {
      return state.courses;
    },
    canLoadMore(state) {
      return state.totalRecords > state.courses.length;
    },
  },
  mutations: {
    setSpecialCourses(state, model) {
      if (model.pagingMode === appConstants.enums.pagingMode.loadMore) {
        state.courses = [...state.courses, ...model.courses.Courses];
      } else {
        state.courses = model.courses.Courses;
      }
      state.totalRecords = model.courses.TotalRecords;
    },
  },
  actions: {
    getSpecialCourses({ commit, state, root, dispatch, getters }, param) {
      let specialCourses = getters.specialCourses;
      if (specialCourses.length == 0 || param.pagingMode === appConstants.enums.pagingMode.loadMore) {
        return servicesFactory
          .createService(servicesFactory.serviceType.enums.services.lmsService)
          .courseService.getSpecialCourses(param)
          .then((response) => {
            commit("setSpecialCourses", {
              courses: response.data,
              pagingMode: param.pagingMode,
            });
          })
          .catch((ex) => {
            if (process.env.VUE_ENV === "client") {
              throw new Error("Server Error");
            }
          });
      } else {
        return specialCourses;
      }
    }
  },
};
