const getDefaultState = () => {
  return {
    form: {},
  };
};
export default {
  namespaced: true, //Avoid of same namespace
  state: getDefaultState(),
  getters: {},
  mutations: {},
  actions: {
    Register({ state, commit }, param) {
      commit("signUp", param.data);
    },
  },
};
