import BaseService from "../base/base.service";
import requestBus from "../../requestBus.js";
import { eventHub } from "../../eventhub";
export default class LmsBaseService extends BaseService {
  constructor() {
    let refererHeader = {};
    if (!(process.env.VUE_ENV === "client")) {
      refererHeader = {
        referer: `${requestBus.$context.request.headers.protocol}://${requestBus.$context.request.headers.host}`,
      };
    }
    super({
      // reqHeader: {
      //   "content-type": "application/json; charset=utf-8",
      //   "api-security-key": process.env.VUE_APP_API_SECURITY_KEY,
      //   ...refererHeader,
      // },
      baseURL: process.env.VUE_APP_API_URL,
    });
    Object.assign(this.configuration, {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "api-security-key": process.env.VUE_APP_API_SECURITY_KEY,
        ...refererHeader,
      },
      callbacks: {
        request: {
          requestParams: { event: "before-request-" },
          onRequest_Event: (request, params, requestParams) => {
            if (!request.headers["contentType"]) {
              request.headers["contentType"] = "application/json; charset=utf-8";
            }
            this.emitLoader(params, requestParams.event);
          },
          errorParams: { event: "request-error-" },
          onError_Event: (error, params, errorParams) => {
            this.emitLoader(params, errorParams.event);
          },
        },
        response: {
          responseParams: { event: "after-response-" },
          onResponse_Event: (response, params, responseParams) => {
            this.emitLoader(params, responseParams.event);
          },
          errorParams: { event: "response-error-" },
          onError_Event: (error, params, errorParams) => {
            this.emitLoader(params, errorParams.event);
          },
        },
      },
    });
  }
  emitLoader = function (params, eventName) {
    if (params) {
      if (params.loader && params.loader.show) {
        let eventHubName = params.loader.id ? eventName + params.loader.id : eventName + "global";
        let message = params.loader.message ? params.loader.message : "";

        eventHub.$emit(eventHubName, {
          message: message,
          showOverlay: params.loader.showOverlay,
        });
      }
    }
  };
}
