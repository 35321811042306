export class CourseModel {
  constructor(PageNumber, PagingMode, CourseGroupCategoryId, ProgramCategoryId, ProgramId, BoardId, CourseGroupId, Course, SubscriptionType) {
    this.PageNumber = PageNumber;
    this.PagingMode = PagingMode;
    this.CourseGroupCategoryId = CourseGroupCategoryId;
    this.ProgramCategoryId = ProgramCategoryId;
    this.ProgramId = ProgramId;
    this.BoardId = BoardId;
    this.CourseGroupId = CourseGroupId;
    this.Course = Course;
    this.SubscriptionType = SubscriptionType
  }
}