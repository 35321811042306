<template>
  <div class="categories-section">
    <div class="row d-flex justify-content-center mt-4">
      <div class="col-md-6">
        <class-menu />
      </div>
      <div class="col-md-6">
        <examMenu />
      </div>
    </div>
  </div>
</template>

<script>
import classMenu from "../views/course/class/menu";
import examMenu from "../views/course/exam/menu";
export default {
  components: {
    classMenu,
    examMenu,
  },
  data() {
    return {};
  },
  
  mounted() {},

  data() {
    return {
      selectedClass: null,
    };
  },
  asyncData({ store, route }) {},
  // Server-side only
  serverPrefetch() {},
  methods: {},
};
</script>
