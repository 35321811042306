<template>
  <div>
    <div class="container">
    <div class="program-loader course-detail-loader mb-3" v-for="i in count" :key="i">
      <VueSkeletonLoader type="rect" :width="1100" :height="10" rounded animation="fade" color="#dddddd" />
    </div>
    </div>
  </div>
</template>
<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["count"],
  components: {
    VueSkeletonLoader,
  },
};
</script>
