import { servicesFactory } from "../../../services/serviceFactory.js";
import { appConstants } from "../../../common/constants.js";
export default {
  namespaced: true,
  state: () => ({
    exams: [],
    totalRecords: 0,
  }),
  getters: {
    popularExams(state) {
      return state.exams;
    },
    canLoadMore(state) {
        return state.totalRecords > state.exams.length
    }
  },
  mutations: {
    setPopularExams(state, model) {
      if (model.pagingMode === appConstants.enums.pagingMode.loadMore) {
        state.exams = [...state.courses, ...model.courses.Courses];
      } else {
        state.exams = model.courses.Courses;
      }
      state.totalRecords = model.courses.TotalRecords;
    },
  },
  actions: {
    getPopularExams({ commit, state, root, dispatch, getters }, param) {
      let popularExams = getters.popularExams;
      if (popularExams.length == 0) {
        return servicesFactory
          .createService(servicesFactory.serviceType.enums.services.lmsService)
          .courseService.getPopularExams(param)
          .then((response) => {
            commit("setPopularExams", {
              courses: response.data,
              pagingMode: param.pagingMode,
            });
          })
          .catch((ex) => {
            if (process.env.VUE_ENV === "client") {
              throw new Error("Server Error");
            }
          });
      } else {
        return popularExams;
      }
    },
  },
};
