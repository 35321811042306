<template>
  <div>
    <template v-if="!loadSkeleton || showEmptyTemplate">
      <slot :isLoading="hideTemplatebeforeReq"> </slot>
    </template>
    <template v-if="loadSkeleton">
      <component :is="id" :count="count"></component>
    </template>
  </div>
</template>
<script>
import { eventHub } from "../../eventhub";
// import programLoader from "./programLoader";
import examCoursesLoader from "./examCoursesLoader.vue";
import classCoursesLoader from "./classCoursesLoader.vue";
import boardLoader from "./boardLoader.vue";
import examLoader from "./examLoader";
import courseDetailLoader from "./courseDetailLoader";
import classLoader from "./classLoader";
import groupLoader from "./boardLoader";
export default {
  components: {
    // programLoader,
    examCoursesLoader,
    classCoursesLoader,
    boardLoader,
    examLoader,
    classLoader,
    groupLoader,
    courseDetailLoader,
  },
  data() {
    return {
      loadSkeleton: false,
      hideTemplatebeforeReq: false,
    };
  },
  props: ["id", "count", "defaultLoadingProp", "showEmptyTemplate"],
  created() {
    this.hideTemplatebeforeReq = this.defaultLoadingProp || false;
    eventHub.$on("before-request-" + this.id, (param) => {
      this.showSkeleton(param);
    });
    eventHub.$on("request-error-" + this.id, (param) => {
      this.hideSkeleton(param);
    });
    eventHub.$on("after-response-" + this.id, (param) => {
      this.hideSkeleton(param);
    });
    eventHub.$on("response-error-" + this.id, (param) => {
      this.hideSkeleton(param);
    });
  },
  methods: {
    showSkeleton(param) {
      this.loadSkeleton = true;
      this.hideTemplatebeforeReq = false;
    },
    hideSkeleton(param) {
      this.loadSkeleton = false;
      this.hideTemplatebeforeReq = true;
    },
  },
  beforeDestroy() {
    eventHub.$off("before-request-" + this.id, this.showSpinner);
    eventHub.$off("request-error-" + this.id, this.hideSpinner);
    eventHub.$off("after-response-" + this.id, this.hideSpinner);
    eventHub.$off("response-error-" + this.id, this.hideSpinner);
  },
};
</script>
