import { render, staticRenderFns } from "./plugin.vue?vue&type=template&id=292bff58&scoped=true&"
import script from "./plugin.vue?vue&type=script&lang=js&"
export * from "./plugin.vue?vue&type=script&lang=js&"
import style0 from "./plugin.vue?vue&type=style&index=0&id=292bff58&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "292bff58",
  null
  
)

export default component.exports