<template>
  <div>
    <div class="program-loader" v-for="i in count" :key="i">
      <VueSkeletonLoader type="circle" :width="10" :height="10" rounded animation="fade" color="#dddddd" />
      <VueSkeletonLoader type="rect" :width="170" :height="10" rounded animation="fade" color="#dddddd" />
    </div>
  </div>
</template>
<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["count"],
  components: {
    VueSkeletonLoader,
  },
};
</script>
