var CryptoJS = require("crypto-js");
let localEnvironment = "development";
export default {
  AES: {
    encrypt(payload, isEncryptionReqForLocalEnv) {
      if (
        (process.env.NODE_ENV &&
          process.env.NODE_ENV != localEnvironment &&
          payload) ||
        isEncryptionReqForLocalEnv
      ) {
        let encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(payload),
          process.env.VUE_APP_CRYPTOJS_KEY
        ).toString();
        return encryptedData;
      } else {
        return payload;
      }
    },
    decrypt(payload, isDecryptionReqForLocalEnv) {
      if (
        (process.env.NODE_ENV &&
          process.env.NODE_ENV != localEnvironment &&
          payload) ||
        isDecryptionReqForLocalEnv
      ) {
        let decryptedData = CryptoJS.AES.decrypt(
          payload,
          process.env.VUE_APP_CRYPTOJS_KEY
        ).toString(CryptoJS.enc.Utf8);
        return decryptedData;
      } else {
        return payload;
      }
    },
  },
  UTF: {
    encrypt(payload) {
      if(payload == undefined || payload == null || payload == '') return '';
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(payload));
    },
    decrypt(payload) {
      if(payload == undefined || payload == null || payload == '') return '';
      return CryptoJS.enc.Base64.parse(payload).toString(CryptoJS.enc.Utf8);
    },
  },
};
