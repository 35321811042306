import { servicesFactory } from "../../../../services/serviceFactory.js";

export default {
  namespaced: true,
  state: () => ({
    sosCategories: [],
    sosCategoriesPopularExams: [],
    academicPrograms: [],
    subscriptionType: null,
  }),
  getters: {
    sosCategories: (state) => (subscriptionType) => {
      let filteredData = state.sosCategories.filter((el) => {
        return el.subscriptionType === subscriptionType;
      });
      return filteredData.length ? filteredData[0].examGroups : [];
    },
    sosCategoriesPopularExams: (state) => (includeGroup) => {
      if (includeGroup) {
        if (state.sosCategoriesPopularExams.length > 0) {
          let filter = [{ CourseGroupName: "All", CourseGroupId: undefined }];
          return [...filter, ...state.sosCategoriesPopularExams];
        }
      }
      return state.sosCategoriesPopularExams;
    },
    academicPrograms(state) {
      return state.academicPrograms;
    },
    getExams: (state, getters) => (courseGroupId, subscriptionType) => {
      let exams = getters.sosCategories(subscriptionType);
      return exams.filter((el) => {
        return el.CourseGroupId == courseGroupId;
      });
      // return state.sosCategories.filter((exam) => {
      //   return exam.CourseGroupId == courseGroupId;
      // });
    },
    isExamExist: (state) => (subscriptionType) => {
      let filteredData = state.sosCategories.filter((el) => {
        return el.subscriptionType === subscriptionType;
      })
      return filteredData.length && filteredData[0].examGroups.length ? true : false;
      // return state.sosCategories.some((el) => {
      //   return el.subscriptionType === subscriptionType;
      // });
    },
  },
  mutations: {
    setSOSCategories(state, model) {
      // state.sosCategories = model.ExamGroups;
      state.sosCategories.push(model);
    },
    setAcedamicPrograms(state, payload) {
      let exams = state.sosCategories.filter(el => {
        return el.subscriptionType === payload.subscriptionType
      })
      state.academicPrograms = exams[0].examGroups.find((exam) => {
        return exam.CourseGroupId == payload.courseGroupId;
      }).AcademicPrograms;
    },
    setSubscriptionType(state, payload) {
      state.subscriptionType = payload;
    },
    sosCategoriesPopularExams(state, payload) {
      state.sosCategoriesPopularExams = payload;
    },
  },
  actions: {
    // getExams({ commit, state }, param) {
    //   if (state.sosCategories.length == 0) {
    //     return servicesFactory
    //       .createService(servicesFactory.serviceType.enums.services.lmsService)
    //       .courseService.getExams(param)
    //       .then((response) => {
    //         // commit("setSOSCategories", response.data);
    //       })
    //       .catch((ex) => {
    //         if (process.env.VUE_ENV === "client") {
    //           throw new Error("Server Error");
    //         }
    //       });
    //   }
    // },
    getExamsGroups({ commit, state, getters, dispatch }, param) {
      // state.sosCategories.length == 0
      let condition = param.data ? !getters.isExamExist(param.data.SubscriptionType) : state.sosCategoriesPopularExams.length == 0;
      if (condition) {
        return servicesFactory
          .createService(servicesFactory.serviceType.enums.services.lmsService)
          .courseService.getExamsGroups(param)
          .then((response) => {
            param.data ? (param.data.SubscriptionType === -1 ? dispatch("populateExamGroups", { subscriptionType: param.data.SubscriptionType, examGroups: response.data.Groups }) : commit("setSOSCategories", { subscriptionType: param.data.SubscriptionType, examGroups: response.data.Groups })) : commit("sosCategoriesPopularExams", response.data.Groups);
            // commit("setSOSCategories", object);
            // commit('sosCategoriesPopularExams', )
            // commit('setSubscriptionType', param.data.SubscriptionType)
          })
          .catch((ex) => {
            if (process.env.VUE_ENV === "client") {
              throw new Error("Server Error");
            }
          });
      }
    },
    getExamsPrograms({ commit, dispatch, getters }, param) {
      if (getters.sosCategories.length <= 0) {
        return dispatch("getExamsGroups", param).then(() => {
          commit("setAcedamicPrograms", {courseGroupId: param.data.courseGroupId, subscriptionType: param.data.SubscriptionType});
        });
      } else {
        commit("setAcedamicPrograms", {courseGroupId: param.data.courseGroupId, subscriptionType: param.data.SubscriptionType});
      }
    },
    populateExamGroups({ commit }, payload) {
      commit("setSOSCategories", payload);
      commit("sosCategoriesPopularExams", payload.examGroups);
    },
  },
};
