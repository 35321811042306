import * as storeKeys from './keys';
import createPersistedState from "vuex-persistedstate";
import * as Cookies from 'js-cookie';
import cookie from 'cookie';
import cookieBus from '../cookieBus.js'
import { appConstants } from "../common/constants";
var CryptoJS = require("crypto-js");

export const classPersistState = createPersistedState({
  key: storeKeys.classes.namespace,
  paths: [storeKeys.classes.namespace],
  storage: {
    getItem: (key) => {
      if (process.env.VUE_ENV === 'client') {
        return Cookies.get(key);
      } else {
        //const parsedCookies = cookie.parse(req.headers.cookie);
        if (cookieBus.$cookie) {
          const parsedCookies = cookie.parse(cookieBus.$cookie)
          return parsedCookies[key];
        }
      }
    },
    setItem: (key, value) => {
      if (process.env.VUE_ENV === 'server') {
        Cookies.set('testHaris', value);
      }
    },
    removeItem: (key) => {

      //if (process.env.VUE_ENV === 'client') {
      Cookies.remove(key);
      // } else {

      // }
    },
  },
});

export const alertPersistState = createPersistedState({
  key: storeKeys.alert.namespace,
  paths: [storeKeys.alert.namespace],
  storage: {
    getItem(key) {
      if (process.env.VUE_ENV === 'client') {
        return sessionStorage.getItem(key);
      }
    },
    setItem(key, value) {
      if (process.env.VUE_ENV === 'client') {
        sessionStorage.setItem(key, value);
      }
    },
    removeItem(key) {
      if (process.env.VUE_ENV === 'client') {
        sessionStorage.removeItem(key);
      }
    },
  },
});

export const cartPersistState = createPersistedState({
  key: storeKeys.cart.namespace,
  paths: [storeKeys.cart.namespace],
  storage: {
    getItem(key) {
      if (process.env.VUE_ENV === 'client') {
        resetCart();
        let cart = sessionStorage.getItem(key)

        return cart;
      }
    },
    setItem(key, value) {
      if (process.env.VUE_ENV === 'client') {
        sessionStorage.setItem(key, value);
      }
    },
    removeItem(key) {
      if (process.env.VUE_ENV === 'client') {
        sessionStorage.removeItem(key);
      }
    },
  },
});

export const checkoutPersistState =
  createPersistedState({
    key: process.env.VUE_APP_CHECKOUT_COOKIE_NAME,
    paths: [storeKeys.checkout.namespace],
    storage: {
      getItem: (key) => {
        if (process.env.VUE_ENV === 'client') {
          resetCart();
          let model = Cookies.get(key);

          if (key != '@@') {
            if (model) {
              model = CryptoJS.AES.decrypt(
                model,
                process.env.VUE_APP_CRYPTOJS_KEY
              ).toString(CryptoJS.enc.Utf8);
            }
          }

          return model;
        }
      },
      setItem: (key, value) => {

        if (process.env.VUE_ENV === 'client') {
          if (key != '@@') {
            value = CryptoJS.AES.encrypt(
              value,
              process.env.VUE_APP_CRYPTOJS_KEY
            ).toString();
          }

          Cookies.set(key, value, {
            //Note: unit of expiry is Days
            //expires: state.auth.appConfig.expiresIn / 60 / 60 / 24,
            //secure: true,
            domain: process.env.VUE_APP_COOKIE_DOMAIN
          });
        }
      },
      removeItem: (key) => {

        if (process.env.VUE_ENV === 'client') {
          Cookies.remove(key);
        }
      },
    },
  });


export const signupPersistState =
  createPersistedState({
    key: process.env.VUE_APP_SIGNUP_COOKIE_NAME,
    paths: [storeKeys.signUp.namespace],
    storage: {
      getItem: (key) => {
        if (process.env.VUE_ENV === 'client') {
          let signup = Cookies.get(key);

          if (key !== '@@') {
            if (signup) {
              signup = CryptoJS.AES.decrypt(
                signup,
                process.env.VUE_APP_CRYPTOJS_KEY
              ).toString(CryptoJS.enc.Utf8);
            }
          }

          return signup;
        }
      },
      setItem: (key, value) => {

        if (process.env.VUE_ENV === 'client') {
          
          if (key != '@@') {
            value = CryptoJS.AES.encrypt(
              value,
              process.env.VUE_APP_CRYPTOJS_KEY
            ).toString();
          }

          Cookies.set(key, value, {
            //Note: unit of expiry is Days
            //expires: state.auth.appConfig.expiresIn / 60 / 60 / 24,
            //secure: true,
            domain: process.env.VUE_APP_COOKIE_DOMAIN
          });
        }
      },
      removeItem: (key) => {

        if (process.env.VUE_ENV === 'client') {
          Cookies.remove(key);
        }
      },
    },
  });


function resetCart() {
  if (process.env.VUE_ENV === 'client') {
    let checkoutCart = Cookies.get(process.env.VUE_APP_CHECKOUT_COOKIE_NAME);

    if (!checkoutCart) {
      sessionStorage.removeItem(storeKeys.cart.namespace);
    } else {
      checkoutCart = CryptoJS.AES.decrypt(
        checkoutCart,
        process.env.VUE_APP_CRYPTOJS_KEY
      ).toString(CryptoJS.enc.Utf8);

      let obj = JSON.parse(checkoutCart);

      if (obj.checkout.items.length > 0 && Number(obj.checkout.items[0].po) === Number(appConstants.enums.PaymentOption.Trial)) {
        sessionStorage.removeItem(storeKeys.cart.namespace);
        Cookies.remove(process.env.VUE_APP_CHECKOUT_COOKIE_NAME)
      }
    }
  }
}