import { appConstants } from "../../../../common/constants.js";
import { servicesFactory } from "../../../../services/serviceFactory.js";
export default {
  namespaced: true,
  state: () => ({
    classes: [],
    // freeClasses: [],
    // allClasses: [],
    subscriptionType: null,
  }),
  getters: {
    classes: (state) => (subscriptionType) => {
      let filteredData = state.classes.filter((el) => {
        return el.subscriptionType === subscriptionType;
      })
      return filteredData.length ? filteredData[0].classes : [];
    },
    // getClass(state) {
    //     return programId => state.classes.filter(function (field) {
    //         return field.AcademicProgramId == programId
    //     });
    // },
    getClass: (state, getters) => (programId, subscriptionType) => {
      // let filteredData =  state.classes.filter(function (field) {
      //     return field.subscriptionType == subscriptionType
      // });
      let classes = getters.classes(subscriptionType);
      return classes.filter((el) => {
        return el.AcademicProgramId == programId;
      });
    },
    isClassExist: (state) => (subscriptionType) => {
        let filteredData = state.classes.filter((el) => {
            return el.subscriptionType === subscriptionType;
          })
          return filteredData.length && filteredData[0].classes.length ? true : false;
      // return state.classes.length && state.subscriptionType === subscriptionType
    },
  },
  mutations: {
    setClasses(state, model) {
      // let classes = model.data.Classes || model
      state.classes.push(model);
    },
    // setFreeClasses(state, model) {
    //     state.freeClasses = model.Classes;
    // },
    // setAllClasses(state, model) {
    //     state.allClasses = model.Classes;
    // },
    setSubscriptionType(state, payload) {
      state.subscriptionType = payload;
    },
  },
  actions: {
    getClasses({ commit, state, root, dispatch, getters }, param) {
      // commit('setClasses', [])
      // if((param.data.SubscriptionType === appConstants.enums.subscriptionType.Free) && state.freeClasses.length) {
      //     commit('setClasses', state.freeClasses)
      // }
      // else if ((param.data.SubscriptionType === appConstants.enums.subscriptionType.All) && state.allClasses.length) {
      //     commit('setClasses', state.allClasses)

      // }
      if (!getters.isClassExist(param.data.SubscriptionType)) {
        return servicesFactory
          .createService(servicesFactory.serviceType.enums.services.lmsService)
          .courseService.getClasses(param)
          .then((response) => {
            //    if(param.data.SubscriptionType === appConstants.enums.subscriptionType.Free) {
            //     commit('setFreeClasses', response.data)
            //     commit("setClasses", response.data);
            //    }
            //    else if(param.data.SubscriptionType === appConstants.enums.subscriptionType.All) {
            //     commit('setAllClasses', response.data)
            //     commit("setClasses", response.data);
            //    }
            commit("setClasses", { subscriptionType: param.data.SubscriptionType, classes: response.data.Classes });
            // commit('setSubscriptionType', param.data.SubscriptionType)
          })
          .catch((ex) => {
            if (process.env.VUE_ENV === "client") {
              throw new Error("Server Error");
            }
          });
      }
    },
  },
};
