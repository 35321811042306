export class CartModel {
  constructor(programCategoryId, courseGroupCategoryId, programId, boardId, courseGroupId, sosId, courseName, paymentOption, sosPaymentPlanId, SOSPaymentPackageId, amount, amountDisplay, amountStrikeThroughDisplay, instituteId, billingPeriodFrequeny, billingPeriodName) {
    this.ProgramCategoryId = programCategoryId;
    this.CourseGroupCategoryId = courseGroupCategoryId;
    this.ProgramId = programId;
    this.BoardId = boardId;
    this.CourseGroupId = courseGroupId;
    this.SOSId = sosId;
    this.CourseName = courseName;
    this.PaymentOption = paymentOption;
    this.SOSPaymentPlanId = sosPaymentPlanId;
    this.SOSPaymentPackageId = SOSPaymentPackageId;
    this.Amount = amount;
    this.AmountDisplay = amountDisplay;
    this.AmountStrikethroughDisplay = amountStrikeThroughDisplay;
    this.InstituteId = instituteId;
    this.BillingPeriodFrequency = billingPeriodFrequeny;
    this.BillingPeriodName = billingPeriodName;
  }
}
