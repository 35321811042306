<template>
  <div>
    <div class="class-courses-loader">
      <div class="container">
        <div class="row-container">
          <div v-for="i in count" :key="i" class="col-lg col-md col-sm"><VueSkeletonLoader type="rect" :width="200" :height="153" rounded animation="fade" color="#dddddd" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  props: ["count"],
  components: {
    VueSkeletonLoader,
  },
};
</script>
<style >
.class-courses-loader .row-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.col-lg,.col-md,.col-sm{
  flex-grow:unset !important;
}
</style>

