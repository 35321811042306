
const getDefaultClassCourses = () => {
  return {
    counter: 0
  };
};

export default {
  namespaced: true,
  state: getDefaultClassCourses(),
  getters: {
    getCounter(state) {
      return state.counter
    }
  },
  mutations: {
    setCounter(state, payload) {
      return state.counter++
    }
  },
  actions: {
      increaseCounter({commit}) {
        debugger
      commit('setCounter')
    }
  },
};
