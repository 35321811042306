<template>
  <div id="chatIcoDiv">
    <!-- <div class="d-block text-center chatIco" v-if="chatUrl">
      <iframe
      class='myframe'
      ref="myChatFrame"
      z-index='999'
      :src="chatUrl"
      width="100%"
      :height="setheight"
      aria-disabled="true"
      frameborder="0"
      allow="encrypted-media,autoplay"
      allowfullscreen
    ></iframe>
    </div> -->
  </div>
</template>

<script>
if (process.env.VUE_ENV === "client") {
  (function (d, src, c) {
    var t = d.scripts[d.scripts.length - 1],
      s = d.createElement("script");
    s.id = "la_x2s6df8d";
    s.async = true;
    s.src = src;
    s.onload = s.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != "complete" && rs != "loaded") {
        return;
      }
      c(this);
    };
    t.parentElement.insertBefore(s, t.nextSibling);
  })(document, "https://kipslms.ladesk.com/scripts/track.js", function (e) {
    LiveAgent.createButton("jxz08dy2", e);
  });
}
export default {
  model: {},
  data() {
    return {
      setheight: "85px",
      chatUrl: "/assets/liveChat.html?ChatGroupId=5",
    };
  },
  methods: {
    setChatHeight() {
      this.setheight = "530px";
      var obj = document.querySelectorAll("#hideonhover");
      obj[0].style.display = "none";
    },
    setChatNone() {
      this.setheight = "85px";
      var obj = document.querySelectorAll("#hideonhover");
      obj[0].style.display = "block";
    },
  },
};
</script>
<style scoped>
#chatIcoDiv {
  position: fixed;
  right: 70px;
  bottom: 0px;
  border: 1px solid green;
  z-index: 99999;
  width: auto;
}

</style>