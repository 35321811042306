import LmsBaseService from "../lmsBase.service";
import { apiEndPoints } from "../constants";

export default class RegisterService extends LmsBaseService {
  constructor() {
    super({});
  }
  registerUser(param) {
    this.configuration.headers = {
      "recaptcha-token": param.token,
    };
    this.configuration.callbacks.params = param.config;
    return this.post(apiEndPoints.register.registerUser, true, param.data);
  }
}
