import LmsBaseService from '../lmsBase.service';
import {
    apiEndPoints
} from '../constants'

export default class ProgramService extends LmsBaseService {
    constructor() {
        super({})
    }
    getProgramList(param) {
        this.configuration.callbacks.params = param.config;
        return this.get(apiEndPoints.courses.getCourses , false, param.data);
    }
}
