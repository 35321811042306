<template>
   <div>
    <b-alert
      class="message-box-app d-flex align-items-center mt-0 mt-md-2"
      :show="dismissCountDown"
      dismissible
      :variant="this.model.Type"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <i :class="messageIcon"></i>
      <div class="alert-cntr">
        <span class="mb0">
          {{ messageHeading }}
        </span>
        <div v-html="this.model.Message"></div>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { appNotification } from "../../../store/keys";
import { AlertTypeEnum } from "./constants";
import AlertModel from "../../../models/app/alertModel";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dismissSecs: 30,
      dismissCountDown: 0,
      messageHeading: "",
      messageIcon: "",
    };
  },
  watch: {
    model(newAlertModel) {
      if (newAlertModel.Message !== "") {
        this.dismissCountDown = this.dismissSecs;
        this.appMessageBody();
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("alert", ["model"]),
  },
  methods: {
    ...mapActions("alert", ["clear"]),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown === 0) {
        this.clear();
      }
    },
    appMessageBody() {
      switch (this.model.Type) {
        case "danger":
          this.messageHeading = "ERROR";
         this.messageIcon = "fas fa-times-circle";
          break;
        case "success":
          this.messageHeading = "SUCCESS";
          this.messageIcon = "fas fa-check-circle";
          break;
        case "warning":
          this.messageHeading = "WARNING";
           this.messageIcon = "fas fa-exclamation-circle";
          break;
      }
    },
  },
};
</script>
<style scoped>
.top-alert-bar {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  text-align: center;
}
.alert-cntr {
  padding-left: 15px;
}
.alert-cntr span {
  font-size: 16px;
  font-weight: 600;
}
.alert-cntr div {
  font-size: 14px;
  font-weight: 400;
}
</style>





