const getDefaultState = () => {
    return {
      cancelTokens: []
    };
  };
  
  export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
      getCancelTokens(state) {
        return state.cancelTokens;
      }
    },
    mutations: {
      setCancelToken(state, payload) {
        state.cancelTokens.push(payload);
      },
      resetState(state) {
        Object.assign(state, getDefaultState());
      }
    },
    actions: {
       cancelReq({ commit, getters }) {
        getters.getCancelTokens.forEach(token => {
          if (token.cancel) {
            token.cancel("req canceled by user");
          }
          commit("resetState");
        });
      },
      addCancelToken({ commit }, payload) {
        commit("setCancelToken", payload);
      }
    }
  };
  