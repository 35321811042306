import { appConstants } from "../../../../common/constants";
import { checkout as checkoutStoreKeys, cart as cartStoreKeys } from "../../../keys";
import { clientStore as store } from "../../../../store";
import { servicesFactory } from "../../../../services/serviceFactory.js";
import cryptojs from "../../../../plugins/crypto";

export default {
  namespaced: true,
  state: {
    cartId: "",
    enrollmentKey: "",
    cart: [],
    sosPaymentPackageId: null,
  },
  getters: {
    totalRecordInCart(state) {
      return state.cart.length;
    },
    cart(state) {
      return state.cart;
    },
    strikeThroughTotalAmount(state) {
      return state.cart.reduce((acc, product) => acc + product.AmountStrikethrough, 0);
    },
    totalAmount(state) {
      return state.cart.reduce((acc, product) => acc + product.Amount, 0);
    },
    isCourseExist(state) {
      return (sosId, SOSPaymentPackageId) =>
        state.cart.some(function (field) {
          return field.SOSId == sosId && field.SOSPaymentPackageId === SOSPaymentPackageId;
        });
    },
    isPaymentPlanExist(state) {
      return (sosId) =>
        state.cart.some(function (field) {
          return field.SOSId == sosId;
        });
    },
    GetSelectedSOSFromCart(state) {
      return (sosId) =>
        state.cart.find(function (sos) {
          if (sos.SOSId == sosId) {
            return sos;
          }
        });
    },
    getCartId(state) {
      return state.cartId;
    },
    getEnrollmentKey(state) {
      return state.enrollmentKey;
    },
    isStrikeThroughPriceAvailable(state) {
      return state.cart.some((course) => {
        return course.AmountStrikethroughDisplay != null;
      });
    },
    selectedSOS: (state) => (SOSId) => {
      return state.cart.find((item) => item.SOSId == SOSId);
    },
    getSelectedSosPaymentPackageId(state) {
      return state.sosPaymentPackageId;
    },
  },
  mutations: {
    add(state, model) {
      if (appConstants.enums.PaymentOption.Trial == model.PaymentOption) {
        state.cart = [];
      }

      if (state.cart && state.cart.length) {
        if (state.cart.some((x) => x.PaymentOption == appConstants.enums.PaymentOption.Trial)) {
          state.cart = [];
        }
      }

      state.cart.push(model);
    },
    remove(state, sosId) {
      let i = state.cart.map((product) => product.SOSId).indexOf(sosId);

      if (i !== -1) {
        state.cart.splice(i, 1);
      }
    },
    setCartId(state, payload) {
      state.cartId = payload.CartId;
    },
    setEnrollmentKey(state, payload) {
      console.log("setEnrollmentKey", payload.PreEnrollmentKey);
      state.enrollmentKey = payload.PreEnrollmentKey;
    },
    setCart(state) {
      state.cart = [];
    },
    setSosPaymentPackageId(state, payload) {
      state.sosPaymentPackageId = payload;
    },
  },
  actions: {
    addToCart({ commit, dispatch, getters }, model) {
      if (model.data.PaymentOption !== appConstants.enums.PaymentOption.Trial && getters.isCourseExist(model.data.SOSId, model.data.SOSPaymentPackageId)) {
        // check isCourseExist if exist then throw exception course already exist
        if (process.env.VUE_ENV === "client") {
          return Promise.reject("Course already exist");
        }
      }
      commit("add", model.data);

      store.dispatch(`${checkoutStoreKeys.namespace}/${checkoutStoreKeys.actions.checkout_cart}`);
    },
    removeFromCart({ state, commit }, model) {
      commit("remove", model.data);
    },
    checkOut({ _, commit, getters, rootState }) {
      let param = {
        data: {
          ...(localStorage.getItem("referrer") && { ReferrerUrl: localStorage.getItem("referrer") }),
          ...(rootState.route.query.ref && { CampaignId: rootState.route.query.ref.slice(0, 32) }),
          ...(getters.getEnrollmentKey && { PreEnrollmentKey: getters.getEnrollmentKey }),
          CartItems: getters.cart,
        },
        config: {
          loader: {
            show: true,
            id: "checkoutLoaderId",
          },
        },
      };
      return servicesFactory
        .createService(servicesFactory.serviceType.enums.services.lmsService)
        .cartService.saveCart(param)
        .then((response) => {
          commit("setCartId", response.data);
          sessionStorage.removeItem(cartStoreKeys.namespace);
          let referrer = cryptojs.UTF.encrypt(process.env.VUE_APP_URL);
          window.location.href = `${process.env.VUE_APP_PORTAL_URL}/invoice/checkOut/${getters.getCartId}/${referrer}`;
        });
    },
    register({ state, commit }, param) {
      return servicesFactory
        .createService(servicesFactory.serviceType.enums.services.lmsService)
        .registerService.registerUser(param)
        .then((response) => {
          commit("setEnrollmentKey", response.data);
        });
    },
    resetCart({ commit }) {
      commit("setCart");
    },
    sosPaymentPackageId({ commit }, id) {
      commit("setSosPaymentPackageId", id);
    },
  },
};
