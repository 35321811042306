<template>
  <div class="program-box first-program">
    <div class="program-left col-6">
      <img src="/assets/images/banner/classes-icon.svg" alt="" loading="lazy" />
      <span class="left-head">Coaching</span>
    </div>

    <div class="program-right col-6">
      <skeleton-loader id="classLoader" :count="4" :showEmptyTemplate="true" :defaultLoadingProp="true">
        <template v-slot:default="{ isLoading }">
          <ul v-if="classes(subscriptionType($route)).length !== 0">
            <li v-on:click="updateSelectedClass(item)" v-for="(item, index) in classes(subscriptionType($route))"
              :key="index">
              <span class="purple-dot"></span>
              {{ item.Name }}
              <!-- <a :title="item.Name" href="javascript:void(0)"></a> -->
            </li>
          </ul>
          <div v-else-if="isLoading">Coming soon</div>
        </template>
      </skeleton-loader>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { appConstants } from "../../../common/constants";
import { classes as classesStoreKeys, course as courseStoreKeys } from "../../../store/keys";
import cryptojs from "../../../plugins/crypto";

export default {
  //props: ["name"],
  // Client-side only
  mounted() {
    this.getClasses();
  },
  computed: {
    ...mapGetters(classesStoreKeys.namespace, classesStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    // courseSubscriptionType() {
    //   let subscriptionType = this.$route.name === "free-trial" ? appConstants.enums.subscriptionType.Free : appConstants.enums.subscriptionType.All;
    //   return subscriptionType;
    // },
  },
  // Server-side only
  async serverPrefetch() {
    return this.getClasses();
  },
  methods: {
    ...mapActions(classesStoreKeys.namespace, classesStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    getClasses() {
      return this.get_Classes({
        data: {
          SubscriptionType: this.subscriptionType(this.$route),
        },
        config: {
          loader: {
            show: true,
            id: "classLoader",
          },
        },
      });
    },
    updateSelectedClass(item) {
      // if (this.selectedCourseInfo.program.id == item.ProgramId) {
      //   return;
      // }
      this.reset_SelectedCourseInfo({});
      this.update_SelectedClass({
        data: {
          program: { id: item.AcademicProgramId, name: item.Name },
          programCategory: {
            id: appConstants.enums.programCategory.Class,
            name: "",
          },
        },
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          let routeName = this.$route.name === "free-trial" ? "FreeTrialClass" : "categoryClass";
          // this.RouteTo("/" + routeName + "/" + this.RemoveSpecialChars(item.Name) + "/" + cryptojs.UTF.encrypt(item.AcademicProgramId));
          this.$router.push({
            name: routeName,
            params: {
              name: this.RemoveSpecialChars(item.Name),
              id: cryptojs.UTF.encrypt(item.AcademicProgramId),
            },
          }).catch(() => { });
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
  },
};
</script>
