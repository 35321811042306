<template>
  <div class="program-box second-program">
    <div class="program-left col-6">
      <img src="/assets/images/banner/exams-icon.svg" alt="" loading="lazy" />
      <span class="left-head">Test Prep</span>
    </div>
    <div class="program-right col-6">
      <skeleton-loader id="examLoader" :count="4" :showEmptyTemplate="true"
        :defaultLoadingProp="true">
        <template v-slot:default="{ isLoading }">
          <ul v-if="sosCategories(subscriptionType($route)).length !== 0">
            <li @click="updateSelectedSOS(sosCategory)" v-for="(sosCategory, index) in sosCategories(subscriptionType($route))" :key="index">
              <span class="purple-dot"></span>
              <span>{{ sosCategory.CourseGroupName }}</span>
            </li>
          </ul>
          <div v-else-if="isLoading">Coming soon</div>
        </template>
      </skeleton-loader>

      <!-- <skeleton-loader id="programLoader" :count="4"></skeleton-loader> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { appConstants } from "../../../common/constants";
import { exam as examStoreKeys, course as courseStoreKeys } from "../../../store/keys";
import cryptojs from "../../../plugins/crypto";
export default {
  //props: ["name"],
  // Client-side only
  mounted() {
    this.getExamsGroups();
  },
  computed: {
    ...mapGetters(examStoreKeys.namespace, examStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    // courseSubscriptionType() {
    //   let subscriptionType = this.$route.name === "free-trial" ? appConstants.enums.subscriptionType.Free : appConstants.enums.subscriptionType.All;
    //   return subscriptionType;
    // },
  },
  // Server-side only
  async serverPrefetch() {
    return this.getExamsGroups();
  },
  methods: {
    ...mapActions(examStoreKeys.namespace, examStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    getExamsGroups() {
      return this.get_Exams_Groups({
        data: {
          SubscriptionType: this.subscriptionType(this.$route),
        },
        config: {
          loader: {
            show: true,
            id: "examLoader",
          },
        },
      });
    },
    updateSelectedSOS(sosCategory) {
      this.update_SelectedExam({
        data: {
          program: { id: sosCategory.CourseGroupId, name: sosCategory.CourseGroupName },
          // sos: { id: exam.SOSId, name: exam.SOSName },
          // programCategory: {
          //   id: appConstants.enums.programCategory.Exam,
          //   name: "",
          // },
          // courseGroup: { id: exam.CourseGroupId, name: "" },
          sosCategory: {
            id: sosCategory.CourseGroupId,
            name: sosCategory.CourseGroupName,
          },
        },
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          //alert(item.Name);
          let routeName = this.$route.name === "free-trial" ? "FreeTrialExam" : "categoryExam";
          // this.RouteTo("/" + routeName + "/" + this.RemoveSpecialChars(this.selectedCourseInfo.sosCategory.name) + "/" + cryptojs.UTF.encrypt(this.selectedCourseInfo.sosCategory.id));
          this.$router.push({
            name: routeName,
            params: {
              name: this.RemoveSpecialChars(this.selectedCourseInfo.sosCategory.name),
              id: cryptojs.UTF.encrypt(this.selectedCourseInfo.sosCategory.id),
            },
          }).catch(() => {});
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    redirectToSelectedcategory(category) {
      // categoryName = categoryName.toLowerCase();
      // this.RouteTo(`/exam/${categoryName}`);
      this.$router.push({
        name: "academicProgram",
        params: {
          academicProgram: category.CourseGroupName,
          courseGroupId: category.CourseGroupId,
        },
      }).catch(() => {});
    },
  },
};
</script>
